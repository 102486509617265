import _ from 'lodash';
import toc_ from '../toc.json';
import { tuple } from './common';

export const defaultToc: Toc = toc_;
export interface TocPage {
  path: string;
  title: string;
  new?: boolean;
  hidden?: boolean;
}

export interface TocSection {
  section: string;
  pages: TocNode[];
  hidden?: boolean;
  new?: boolean;
}

export type TocNode = TocPage | TocSection;
export interface Toc {
  sections: TocSection[];
}

export interface NodeInfo {
  fields?: {
    slug: string;
  };
  slug?: string;
  frontmatter: {
    title: string;
  };
  headings: {
    value: string;
    depth: number;
  }[];
  parent?: {
    title: string;
  };
}

export function walkAllPagesAndAncestors(toc = defaultToc): [TocPage, TocSection[]][] {
  function* walk(node: TocNode, ancestors: TocSection[]): Generator<[TocPage, TocSection[]]> {
    if ('pages' in node) {
      const newAncestors = [...ancestors, node];
      for (const child of node.pages) {
        yield* walk(child, newAncestors);
      }
    } else {
      yield tuple(node, ancestors);
    }
  }
  return _.flatMap(toc.sections, (section) => [...walk(section, [])]);
}

export function walkAllPages(toc = defaultToc): TocPage[] {
  return walkAllPagesAndAncestors(toc).map(([page]) => page);
}
