import { PlasmicComponent } from '@plasmicapp/loader-gatsby';
import { Link } from 'gatsby';
import React, { useContext } from 'react';
import { ensure, ensureTrailingSlash } from '../common';
import { defaultToc, TocNode, TocPage, TocSection, walkAllPagesAndAncestors } from '../toc';
import { PageInfo } from './PageInfo';
import { SidebarContainer } from './SidebarContainer';

interface SidebarContextData {
  currentPageAndAncestors: [TocPage, TocSection[]] | undefined;
}

const SidebarContext = React.createContext<SidebarContextData | undefined>(undefined);

const INDENT_SIZE = 6;

interface NavNodeProps {
  node: TocNode;
  indent: number;
}

function NavNode({ indent, node }: NavNodeProps) {
  const { location } = ensure(useContext(PageInfo));
  if ('path' in node) {
    const page = node;
    const fullPath = ensureTrailingSlash(page.path);
    const isCurrentPage = ensureTrailingSlash(location.pathname) === fullPath;
    if (page.hidden && !isCurrentPage) {
      return null;
    }

    return (
      <PlasmicComponent
        component="TocNode"
        componentProps={{
          children: page.title,
          page: true,
          isCurrent: isCurrentPage,
          isNew: page.new,
          root: {
            as: Link,
            props: {
              to: fullPath
            }
          },
          content: {
            style: {
              marginLeft: `${indent * INDENT_SIZE}px`
            }
          }
        }}
      />
    );
  } else {
    return <NavSection indent={indent} section={node} isNew={node.new} />;
  }
}

interface SectionProps {
  section: TocSection;
  isNew?: boolean;
  indent: number;
}

function NavSection({ indent, section, isNew }: SectionProps) {
  const { currentPageAndAncestors } = ensure(useContext(SidebarContext));
  const isAncestorOfCurrentPage = currentPageAndAncestors?.[1].includes(section);
  const [expanded, setExpanded] = React.useState(isAncestorOfCurrentPage);
  return (
    <>
      <PlasmicComponent
        component="TocNode"
        componentProps={{
          root: {
            as: 'div',
            props: {
              onClick: () => {
                setExpanded(!expanded);
              }
            }
          },
          section: true,
          isNew,
          expandChevron: {
            style: {
              transform: expanded ? undefined : 'rotate(-90deg)',
              transition: 'transform 0.1s'
            }
          },
          content: {
            style: {
              marginLeft: `${indent * INDENT_SIZE}px`
            }
          },
          children: section.section
        }}
      />
      {expanded &&
        section.pages.map((node) => (
          <NavNode key={'title' in node ? node.title : node.section} node={node} indent={indent + 1 + 1} />
        ))}
    </>
  );
}

export function Sidebar() {
  const { location } = ensure(React.useContext(PageInfo));

  const currentPageAndAncestors = walkAllPagesAndAncestors().find(
    ([page]) => ensureTrailingSlash(page.path) === ensureTrailingSlash(location.pathname)
  );

  return (
    <SidebarContext.Provider value={{ currentPageAndAncestors }}>
      <SidebarContainer>
        {defaultToc.sections.map((section) => {
          if (section.section === '') {
            return section.pages.map((node) => (
              <NavNode key={'title' in node ? node.title : node.section} node={node} indent={0} />
            ));
          } else if (section.hidden && !currentPageAndAncestors?.[1].includes(section)) {
            return null;
          } else {
            return <NavSection key={section.section} section={section} indent={0} />;
          }
        })}
      </SidebarContainer>
    </SidebarContext.Provider>
  );
}
