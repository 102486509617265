module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-remark-images@7.12.0_gatsby-plugin-sharp@5.12.0_gatsby@5.12.3_babel-eslint@10.1.0_esli_lu7bpqqtwqu6gdvmspp3mwvi2i/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1140,"quality":90,"linkImagesToOriginal":false,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-remark-autolink-headers@6.12.0_gatsby@5.12.3_babel-eslint@10.1.0_eslint@7.32.0__eslint_7sqvrisnjox6xokfh3wusein24/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"className":"anchor","icon":"<span></span>","isIconAfterHeader":true,"offsetY":0},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-canonical-urls@5.12.0_gatsby@5.12.3_babel-eslint@10.1.0_eslint@7.32.0__eslint-i_qkttijccpf5s5263ofphfvteiq/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://docs.plasmic.app"},
    },{
      plugin: require('../plugins/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-catch-links@5.12.0_gatsby@5.12.3_babel-eslint@10.1.0_eslint@7.32.0__eslint-impo_rvcwjnwcjlwj3ajvbsqgkn5czu/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[],"excludePattern":"intake"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.12.3_babel-eslint@10.1.0_eslint@7.32.0__eslint-import-resolver-typescript@2.7.1_reac_zeo3ybgctsgvcrwnaf4mceanhy/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
